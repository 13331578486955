import type { RuntimeConfig } from 'nuxt/schema'

/**
 * Подключается в компоненте прямо в функции/объекте head после других настоек link/meta
 * Сделано чтоб не перезаписывались head тэги функцией компнента.
 */
export default function link({ exclude, data }: {
  exclude?: string[]
  data: {
    route: ReturnType<typeof useRoute>
    config: RuntimeConfig
  }
}) {
  const path = excluder({ origin: data.route.path, exclude })

  return {
    link: [
      {
        hid: 'canonical',
        rel: 'canonical',
        href: `${data.config.public.projectUrl}${path}`,
      },
    ],
  }
}

/**
 * Функция помощник. Если строка заканчивается на элемент в массиве exlude удалит подстроку
 * Возвращает новое значение origin
 */

function excluder({ exclude, origin }: { exclude?: string[]; origin: string }) {
  let res = origin

  if (exclude) {
    exclude.forEach((item) => {
      if (origin.endsWith(item) && res)
        res = res.replace(new RegExp(`${item}\/?$`, 'gi'), '')
    })
  }

  return res
}
